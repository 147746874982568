import posthog from "posthog-js";
import { useCookie } from "./cookie";


export type PosthogPayload = {
    [key: string]: string | string[] | number | number[] | boolean | null | PosthogPayload;
};

export type PosthogAction =
    'accept_invite' |
    'decline_invite' |
    'forgot_password' |
    'invite_learner' |
    'login' |
    'reset_password' |
    'schedule_review' |
    'scroll' |
    'signup'

export type PosthogPhase =
    'complete' |
    'start'

export function initPosthog(token:string) {
    posthog.init(
        token,
        {
            api_host: 'https://grok.meetbackstage.com',
            autocapture: false,
            capture_pageview: false,
            capture_pageleave: false,
            ui_host: 'https://us.posthog.com'
        }
    );
}

function capturePosthogEvent(eventName:string, version:number, payload?: PosthogPayload) {
    const cookie = useCookie(import.meta.env.VITE_EMAIL_CAMPAIGN_KEY)

    const eventProperties = {
        payload: payload,
        version: version,
        email_campaign: cookie.cookie.value
    }

    posthog.capture(eventName, eventProperties);
}

export function capturePosthogAction(action:PosthogAction, phase:PosthogPhase, version: number, payload?: PosthogPayload) {
    if (payload === undefined)
        payload = {}

    capturePosthogEvent(`${action}.${phase}`, version, payload)
}

export function capturePosthogPageview(path:string) {
    const cookie = useCookie(import.meta.env.VITE_EMAIL_CAMPAIGN_KEY)
    posthog.capture('$pageview', { path: path, email_campaign: cookie.cookie.value });
}
